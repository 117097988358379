import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import useChallengeCount from '@app/web/src/hooks/utils/useChallengeCount';
import useMemberClubRole from '@app/web/src/hooks/utils/useMemberClubRole';
import usePrefetchPages from '@app/web/src/hooks/utils/usePrefetchPages';
import {
  ChatHashtag as ChatHashtagIcon,
  ChatQuestionList as ChatQuestionListIcon,
  MainChallenge as MainChallengeIcon,
  NFTFavorite as NFTFavoriteIcon,
  OtherFriendsPlaylist as OtherFriendsPlaylistIcon,
  TestBookmarkFilled as TestBookmarkFilledIcon,
  TestHistory as TestHistoryIcon,
  TestWriting as TestWritingIcon,
} from '@front/icon';
import { useIaClubStatus } from '@lib/web/hooks';
import { MenuComps } from '@lib/web/ui';

import useMenuCurrentClubSlug from '../../../../hooks/useMenuCurrentClubSlug';

function usePrefetchData() {
  const clubSlug = useMenuCurrentClubSlug();

  // prefetch data for the challenges page
  void useChallengeCount(clubSlug);
}

export default function PlaylistsMenu() {
  const { t } = useTranslation();
  const clubSlug = useMenuCurrentClubSlug();
  const { club, joined } = useIaClubStatus(clubSlug);
  const { hasEditAuth } = useMemberClubRole();
  void usePrefetchData();

  usePrefetchPages([
    '/club/[slug]/playlists/history',
    '/club/[slug]/playlists/saved',
    '/club/[slug]/playlists/emoji',
    '/club/[slug]/playlists/friends-playlists',
    '/club/[slug]/playlists/hashtags',
    '/club/[slug]/playlists/creators',
    '/club/[slug]/challenges',
    '/club/[slug]/create-questions',
    '/club/[slug]/review-questions',
  ]);

  if (!club || joined === undefined)
    return <MenuComps title={<Skeleton width={100} />} />;

  return (
    <MenuComps title={t('menu.Playlists')}>
      <MenuComps.Section>
        <MenuComps.SubTitle title="Playlists" />
        <MenuComps.Button
          icon={<TestHistoryIcon />}
          href={`/club/${club.clubSlug}/playlists/history`}
        >
          {t('menu.History')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<MainChallengeIcon />}
          href={`/club/${club.clubSlug}/challenges`}
        >
          {t('menu.Challenges')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestBookmarkFilledIcon />}
          href={`/club/${club.clubSlug}/playlists/saved`}
        >
          {t('menu.Saved Playlists')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<NFTFavoriteIcon />}
          href={`/club/${club.clubSlug}/playlists/emoji`}
        >
          {t('menu.Reaction')}
        </MenuComps.Button>
      </MenuComps.Section>

      <MenuComps.Section>
        <MenuComps.SubTitle title="Explore" />
        <MenuComps.Button
          icon={<OtherFriendsPlaylistIcon />}
          href={`/club/${club.clubSlug}/playlists/friends-playlists`}
        >
          {t('menu.Followings', "Following's")}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<ChatHashtagIcon />}
          href={`/club/${club.clubSlug}/playlists/hashtags`}
        >
          {t('menu.Hashtags')}
        </MenuComps.Button>
        <MenuComps.Button
          icon={<TestWritingIcon />}
          href={`/club/${club.clubSlug}/playlists/creators`}
        >
          {t('menu.Creators')}
        </MenuComps.Button>
      </MenuComps.Section>

      <MenuComps.Section>
        <MenuComps.SubTitle title="Create" />
        <MenuComps.Button
          icon={<ChatQuestionListIcon />}
          href={`/club/${club.clubSlug}/create-questions`}
        >
          {t('menu.My Questions', 'My Questions')}
        </MenuComps.Button>

        {hasEditAuth && (
          <MenuComps.Button
            icon={<ChatQuestionListIcon />}
            href={`/club/${club.clubSlug}/review-questions`}
          >
            {t('menu.Review Questions')}
          </MenuComps.Button>
        )}
      </MenuComps.Section>
    </MenuComps>
  );
}
