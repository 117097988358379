import { ClubGroupViewSlug, useIaClubGroups } from '@lib/web/apis';

export const useDiscoverClubGroups = () => {
  return useIaClubGroups({
    viewSlug: ClubGroupViewSlug.CardCenterClubGroupDefault,
    orderBy: 'officialType;name',
    sortBy: 'desc;asc',
    limit: 21, // there is 1 'create card' on the top, so instead of 20, we use 21 to make loading looks better
  });
};
